<template>
  <div class="page">
    <!--标题-->
    <ls-title title="授权记录"></ls-title>
    <!-- 条件框 -->
    <div class="conddiv">
      <span></span>
      <el-date-picker v-model="dateTime" type="daterange" range-separator="至" format="YYYY 年 MM 月 DD 日"
                value-format="YYYY-MM-DD" start-placeholder="开始日期" end-placeholder="结束日期" clearable>
      </el-date-picker>
      <el-button type="warning" style="margin-left:5px;" @click="search">搜索</el-button>
    </div>

    <!-- 表格 -->
    <el-table :data="tableData" border style="width: 100%;border-radius: 10px;" :header-cell-style="{ background: '#285df0'}" :empty-text="emptyText">
      <el-table-column prop="createTime" label="授权时间" label-class-name="tablehead" align="center " />
      <el-table-column v-if="role == 1" prop="unitName" label="机构名称" label-class-name="tablehead" align="center " />
      <el-table-column prop="content" label="信息内容" label-class-name="tablehead" align="center " >
        <template #default="scope">
          <span v-if="scope.row.empowerType == 1">授权短信</span>
          <span v-if="scope.row.empowerType == 2">授权语音</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="数量信息" label-class-name="tablehead" align="center ">
        <template #default="scope">
          <span v-if="scope.row.operateType == 1">+ {{scope.row.number}}</span>
          <span v-if="scope.row.operateType == 2">- {{scope.row.number}}</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="pagination">
      <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
    </div>
  </div>
</template>

<script>
import {getEmpowerLogInfos } from '../../../api/api'
import session from "../../../store/store";
import until from '../../../until/until'

export default {
  data() {
    return {
      emptyText:'加载中',
      dateTime: '',//条件
      startTime:'',
      endTime:'',
      currentPage: 1,
      size: 10,
      total: 0,
      role:session.getLoginInfo().accountType,
      tableData: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getEmpowerLogInfos();
    },
    getEmpowerLogInfos() {
      var uuidType = 1
      if(this.role == 1) {
        uuidType = 1
      }else {
        uuidType = 2
      }
      var param = {
        uuid:session.getLoginInfo().unitUuid,
        uuidType:uuidType,
        page:this.currentPage - 1,
        size:this.size,
        startTime:this.startTime,
        endTime:this.endTime
      }
      getEmpowerLogInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.tableData = res.data.dataInfo;
        }else {
          this.total = 0;
          this.tableData.splice(0, this.tableData.length);
          this.emptyText = "暂无数据"
        }
      })
    },
    search() {
      if (!until.isNULL(this.dateTime)) {
        this.startTime = this.dateTime[0],
        this.endTime = this.dateTime[1]
      }else {
        this.startTime = '',
        this.endTime = ''
      }
      this.getEmpowerLogInfos();
    },

      //分页
    handleSizeChange(val) {
      this.size = val;
      this.getEmpowerLogInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getEmpowerLogInfos();
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0px 20px;
}
.conddiv {
  background-color: white;
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 0px 2px 3px 4px var(--el-color-info-light);
  margin-bottom: 20px;
}
.pagination {
  background-color: white;
  text-align: center;
  padding: 20px 0px;
  margin-top: 20px;
  border-radius: 10px;
}
.textbtn {
  color: #285df0;
  cursor: pointer;
}
</style>
<style>
/* .el-input__prefix {
  top: 5px !important;
} */
.tablehead {
  color: white;
}
</style>